import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';



export const fetchNetworks = createAsyncThunk("fetch-networks", async (args, {
    rejectWithValue

}) => {

    try {
        let base_url = process.env.REACT_APP_API
        let route = "/networks"
        let data = await axios.get(base_url + route)
        let networks = (data.data)
        return networks

    } catch (error) {

        return rejectWithValue(error.response.data)
    }
})


const networksSlice = createSlice({
    name: 'networks',
    initialState: {
        networks: [],
        currenciesPrices: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
        updatePrices: (state, action) => {
            state.networks = action.payload
        }
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchNetworks.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchNetworks.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.networks = action.payload
        })
        builder.addCase(fetchNetworks.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default networksSlice.reducer
