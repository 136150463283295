import React, { useEffect, useRef, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import CancelPopup from "../CancelPopup/CancelPopup";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useSelector } from "react-redux";
import { parseTickers } from "../../../../../redux/tickers";
import axios from "axios";
import Cookies from "js-cookie";
import { useTheme } from "ThemeContext/ThemeContext";
import Decimal from "decimal.js";

const SingleRow = ({ setOpenOrders, el }) => {
  let {
    time,
    pair,
    type,
    side,
    limit_price: price,
    quantity: amount,
    filled_quantity: filled,
    quantity: initial_quantity,
    triggerCondition,
    id,
    order_id,
    timestamp,
    cancelAll,
    order_type,
  } = el;

  const cancelRef = useRef();
  const [isShowCancelPopup, setIsShowCancelPopup] = useState(false);
  const [cancel, setCancel] = useState(false);

  let markets = useSelector(parseTickers);
  let market = markets.find((market) => market.pair === pair);
  let unFilled = initial_quantity - filled;

  timestamp = timestamp / 1000;
  let date = new Date(timestamp).toLocaleString();
  useOnClickOutside(cancelRef, () => setIsShowCancelPopup(false));

  let price_decimal = new Decimal(price);
  let amount_decimal = new Decimal(amount);
  let total = price_decimal.mul(amount_decimal).toNumber();

  const { isDarkTheme } = useTheme();
  return (
    <>
      <tr
        className={clsx(
          classes.row,
          classes.valueContainer,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <td className={clsx(classes.item, classes.firstItem)}>{
          // split at - and take last element
          id.split("-")[4]

        }</td>
        <td className={clsx(classes.item, classes.item)}>{date}</td>
        <td className={clsx(classes.item, classes.pair)}>{`${market?.base_asset?.toUpperCase()}/${market?.quote_asset?.toUpperCase()}`}</td>
        <td className={classes.item}>{order_type}</td>
        <td
          className={clsx(
            classes.item,
            side.toLowerCase() === "buy" && classes.buy,
            side.toLowerCase() === "sell" && classes.sell
          )}
        >
          {side}
        </td>
        <td className={classes.item}>
          {price} {market?.quote_asset?.toUpperCase()}
        </td>
        <td className={classes.item}>
          {amount} {market?.base_asset?.toUpperCase()}
        </td>
        <td className={classes.item}>
          {filled} {market?.base_asset?.toUpperCase()}
        </td>
        <td className={classes.item}>
          {unFilled} {market?.base_asset?.toUpperCase()}
        </td>
        <td className={classes.item}>{"GTC"}</td>
        <td className={classes.item}>
          {total.toFixed(2)} {market?.quote_asset?.toUpperCase()}
        </td>

        <td className={clsx(classes.item, classes.lastItem)}>
          <div className={classes.buttonContainer}>
            <RiDeleteBin6Line
              className={classes.deleteIcon}
              color={isShowCancelPopup ? "var(--color-red)" : null}
              onClick={() => setIsShowCancelPopup(true)}
            />
            <div ref={cancelRef}>
              <CancelPopup
                setOpenOrders={setOpenOrders}
                isActive={isShowCancelPopup}
                setCancel={setCancel}
                setIsShowCancelPopup={setIsShowCancelPopup}
                pair={pair}
                orderID={order_id ? order_id : id}
              />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
