import React, { useState } from "react";
import classes from "./Spot.module.css";
import Heading from "components/common/Heading/Heading";

import { nexo } from "assets";
import FilterButton from "../../../components/Wallet/FilterButton/FilterButton";

import SpotTable from "../../../components/Wallet/Spot/SpotTable/SpotTable";
import { useSelector, useDispatch } from "react-redux";

import { useEffect } from "react";
import { fetchBalance } from "../../../redux/balance";

import ConvertModal from "modals/FundingsModals/ConvertModal/ConvertModal";
import TransferModal from "modals/FundingsModals/TransferModal/TransferModal";
import RecentTransaction from "components/Wallet/WalletOverview/RecentTransaction/RecentTransaction";
import Button from "components/Button/Button";
import { selectCurrencyPrices } from "../../../redux/currencies";
import FundingSendModal from "modals/FundingsModals/FundingSendModal/FundingSendModal";
import EnterAmountModal from "modals/FundingsModals/EnterAmountModal/EnterAmountModal";
import { useTheme } from "ThemeContext/ThemeContext";
import clsx from "clsx";

const Spot = ({ name }) => {
  const { isDarkTheme } = useTheme();
  const [balances, setBalances] = useState([]);
  // const [currencies, setCurrencies] = useState([]);
  const dispatch = useDispatch();
  let prices = useSelector(selectCurrencyPrices);
  const [isCovertModalActive, setIsConvertModalActive] = useState(false);
  const [isTransferModalActive, setIsTransferModalActive] = useState(false);
  const [isEnterAmountModalActive, setEnterAmountModalActive] = useState(false);
  const [total_balance, setTotal] = useState(0);
  const [totalWalletBalance, setTotalWalletBalance] = useState(0);
  const [default_currency, setDefaultCurrency] = useState(null);
  const [isSendModalActive, setIsSendModalActive] = useState(false);
  const [isConfirmationModalActive, setIsConfirmationModal] = useState(false);

  function openModalFromWallet(defaultCurrency) {
    setDefaultCurrency(defaultCurrency);
    setIsTransferModalActive(true);
  }

  let currencies = useSelector((state) => state.currencies.currencies);

  // alert(s)

  let unfiltered_balance = useSelector((state) => state.balances);

  console.log(unfiltered_balance);
  function filterBalance() {
    let filtered_balance = unfiltered_balance.balances.filter(
      (balance) => balance.wallet_type.toLowerCase() == name.toLowerCase()
    );
    setBalances(filtered_balance);
  }

  function totalBalance() {
    // alert(name)

    if (name == "papertrading") {
      setTotal(0);
      return;
    }
    let total_balance = 0;
    for (let i = 0; i < balances.length; i++) {
      //   {
      //     "token": "eth",
      //     "price": 2
      // }
      let balance = balances[i];

      let price = prices.find((price) => price.token == balance.currency);
      if (!price) {
        setTotal(0);
      }
      if (price != 0 && balance.balance != 0) {
        let bal_to_add = balance.balance;
        let price_to_add = price.price;

        let final_balance = bal_to_add * price_to_add;
        total_balance += final_balance;
        setTotal(total_balance);
      }
    }
  }

  useEffect(() => {
    dispatch(fetchBalance());
  }, [name]);

  useEffect(() => {
    totalBalance();
    filterBalance();
  }, [unfiltered_balance, name]);
  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <Heading heading={`${name.toUpperCase()} Wallet`} border />

      <div className={classes.container}>
        <div className={classes.header}>
          {/* <Heading heading={`${name.toUpperCase()} Wallet`} /> */}
          {/* <FilterButton onClick={() => { }} mlAuto>

          </FilterButton> */}
        </div>{" "}
        <div className={classes.balanceContainer}>
          {/* <div>
            <p className={classes.balance}>{total_balance.toFixed(2)} USD</p>
            <p className={classes.usdBalance}>
              {" "}
              <span>≈ </span> ${totalWalletBalance}
            </p>
          </div> */}
          <Button
            size="md"
            btnGray
            mlAuto
            onClick={() => {
              setIsTransferModalActive(true);
            }}
            className={classes.button}
          >
            Transfer
          </Button>
        </div>
        <SpotTable
          setTotalWalletBalance={setTotalWalletBalance}
          data={currencies}
          balances={balances}
          name={name}
          openModalFromWallet={openModalFromWallet}
          setIsSendModalActive={setIsSendModalActive}
        />
        {/* <ConvertModal
          isActive={isCovertModalActive}
          onClose={() => setIsConvertModalActive(false)}
        />{" "} */}
        <TransferModal
          isActive={isTransferModalActive}
          defaultCurrency={default_currency}
          onClose={() => setIsTransferModalActive(false)}
        />
        <FundingSendModal
          isActive={isSendModalActive}
          onClose={() => setIsSendModalActive(false)}
          setEnterAmountModalActive={setEnterAmountModalActive}
        />
        <EnterAmountModal
          isActive={isEnterAmountModalActive}
          onClose={() => setEnterAmountModalActive(false)}
          setIsConfirmationModal={setIsConfirmationModal}
        />
      </div>
      <RecentTransaction />
    </div>
  );
};

export default Spot;
