import { NavLink } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import clsx from "clsx";

import classes from "./Sidebar.module.css";
import { useState } from "react";
import { useTheme } from "../../../ThemeContext/ThemeContext";

const wallets = [
  // {
  //   label: "Overview",
  //   to: "overview",
  // },
  {
    label: "Spot",
    to: "spot",
  },
  {
    label: "Funding ",
    to: "funding",
  },

  {
    label: "Staking ",
    to: "staking",
  },

  {
    label: "Paper Trading",
    to: "papertrading",
  },
];

const Sidebar = () => {
  const { isDarkTheme } = useTheme();
  const [activeSidebar, setActiveSidebar] = useState(false);
  return (
    <div className={(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div
        className={classes.headingContainer}
        onClick={() => setActiveSidebar(true)}
      >
        <h3 className={clsx(classes.link, classes.heading)}>My Wallet</h3>
        <FaAngleDown className={classes.arrow} />
      </div>
      <div
        className={clsx(
          classes.sidebar,
          activeSidebar && classes.activeSidebar
        )}
      >
        <IoMdClose
          className={classes.close}
          onClick={() => setActiveSidebar(false)}
        />
        <div className={classes.walletTabs}>
          <div className={classes.wallets}>
            {wallets.map((el, idx) => {
              return (
                <NavLink
                  key={idx}
                  to={el.to}
                  className={({ isActive }) =>
                    clsx(isActive && classes.active, classes.link)
                  }
                  onClick={() => setActiveSidebar(false)}
                >
                  {el.label}
                </NavLink>
              );
            })}
          </div>
        </div>
        {/* <div className={classes.depositAndWithdraw}>
          {" "}
          <NavLink
            to="/staking"
            className={({ isActive }) =>
              clsx(isActive && classes.active, classes.link, classes.deposit)
            }
            onClick={() => setActiveSidebar(false)}
          >
            Staking
          </NavLink>
          <NavLink
            to="/rewards"
            className={({ isActive }) =>
              clsx(isActive && classes.active, classes.link)
            }
            onClick={() => setActiveSidebar(false)}
          >
            Rewards
          </NavLink>
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
