import React, { useState } from "react";
import classes from "./SpotPage.module.css";
import Toolbar from "components/Spot/Toolbar/Toolbar";
import History from "components/Spot/History/History";

import OrderBookRecentTrades from "components/Spot/OrderBookRecentTrades/OrderBookRecentTrades";
import Trades from "components/Spot/Trades/Trades";
import Pairs from "components/Spot/Pairs/Pairs";
import BuySell from "components/Spot/BuySell/BuySell";
import Button from "components/Button/Button";
import BuyModal from "modals/SpotMobileUi/BuySellModal/BuySellModal";
import BuySellModal from "modals/SpotMobileUi/BuySellModal/BuySellModal";
import MobileLayoutBuySell from "components/Spot/BuySell/MobileLayoutBuySell/MobileLayoutBuySell";
import clsx from "clsx";
import OrderBook from "components/Spot/OrderBookRecentTrades/OrderBook/OrderBook";
import RecentTrades from "components/Spot/OrderBookRecentTrades/RecentTrades/RecentTrades";
import TradingChart from "components/Spot/Chart/TradingChart";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TVChartContainer } from "components/TVChartContainer";
import { parseTickers } from "../../redux/tickers";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useTheme } from "ThemeContext/ThemeContext";
import useWebSocket from "react-use-websocket";
import { fetchBalance } from "../../redux/balance";
import Decimal from "decimal.js";
import axios from "axios";
import { toast } from "react-toastify";

const SportPage = () => {
  const [amount, setAmount] = useState(1);
  const { isDarkTheme } = useTheme();
  let { market_pair } = useParams();
  let [bids, setBids] = useState([]);
  let [asks, setAsks] = useState([]);
  let [tickers, setTickers] = useState();
  let [recentTrades, setRecentTrades] = useState([]);


  const [amountPrecision, setAmountPrecision] = useState(3);
  const [pricePrecision, setPricePrecision] = useState(8);

  // let dispatch = useDigit

  let [pair, setPair] = useState(market_pair.toLowerCase());

  let [bidSideAmount, setBidSideAmount] = useState();
  let [askSideAmount, setAskSideAmount] = useState();

  let [price, setPrice] = useState();

  let [openOrders, setOpenOrders] = useState([]);
  let [tradeHistory, setTradeHistory] = useState([]);
  let [orderHistory, setOrderHistory] = useState([]);

  let [orderbookws, setOrderBookWs] = useState(null);
  let [historyws, setHistoryWs] = useState(null);

  async function resetOrderBook() {
    setBids([]);
    setAsks([]);
  }

  async function resetHistory() {
    setOpenOrders([]);
    setTradeHistory([]);
    setOrderHistory([]);
  }

  async function setMarketPair() {
    if (!market_pair) {
      setPair("uns-usdt");
    }
  }

  useEffect(() => {
    setMarketPair();
  }, []);

  async function getOpenOrders() {
    console.log("getting open orders pair ", pair);
    let api = process.env.REACT_APP_API + "/spot/orders/open?pair=" + pair;
    let token = Cookies.get("auth-token");


    try {
      let { data, status } = await axios.get(api, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log("open orders ", data);
      setOpenOrders(data ? data : []);
    } catch (e) {
      console.log("open orders ", e);
      console.log(e);
    }
  }

  async function getRecentTrades() {
    console.log("getting open orders pair ", pair);
    let api = process.env.REACT_APP_API + "/spot/trades?pair=" + pair;
    let token = Cookies.get("auth-token");


    try {
      let { data, status } = await axios.get(api, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log("open orders ", data);

      console.log("settingRecentTrades", data)
      setTradeHistory(data ? data : []);
    } catch (e) {
      console.log("open orders ", e);
      console.log(e);
    }
  }
  useEffect(() => {
    getOpenOrders();
    getRecentTrades();
  }, [pair, tickers, bids, asks]);
  let markets = useSelector(parseTickers);

  let market = markets.find((market) => market?.pair == pair);
  // console.log("maaarrket ", markets);
  const socketUrl = process.env.REACT_APP_WSS + "/websocket";
  // const socketUrl = "ws://localhost:8080/websocket";
  let subscription_message = JSON.stringify({
    // operation: "subscribe",
    // channel: ["marketdata", "orders"],
    // interval: 2,
    // market: pair,
    // topics: [
    //   "tickers",
    //   "orderbook",
    //   "trades",
    //   "open_orders",
    //   "trade_history",
    //   "order_history",
    // ],
    // auth_token: Cookies.get("auth-token"),

    "channel": "MarketData",
    "interval": 3,
    "pair": pair,
    "filters": ["Trades", "Tickers", "OrderBooks"]
  });

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.log(subscription_message);
      sendMessage(subscription_message);
      //
    },

    onMessage: (event) => {
      console.log(event)
      pushBook(event);
      pushHistory(event);
      pushTradesHistory(event);
      tickerHandler(event);
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,

    onError: (event) => {
    },
  });

  // async function fetchBookFromWs() {
  //   let socket = new WebSocket(process.env.REACT_APP_WSS + "/ws/subscribe");
  //   let random = Math.random().toFixed(0);
  //   socket.onopen = function (event) {
  //     socket.send(
  //       JSON.stringify({
  //         sub_id: random,
  //         operation: "subscribe",
  //         channel: ["marketdata", "orders"],
  //         interval: 2,
  //         market: pair,
  //         topics: [
  //           "tickers",
  //           "orderbook",
  //           "trades",
  //           "open_orders",
  //           "trade_history",
  //           "order_history",
  //         ],
  //         auth_token: Cookies.get("auth-token"),
  //       })
  //     );
  //   };

  //   socket.onmessage = function (event) {

  //   };
  // }

  async function pushBook(event) {
    let data = JSON.parse(event.data);
    console.log("book event", data)

    if (data.ts && data.reset) {
      orderBookresetHandler(data);
      return;
    }
    if (data.channel.toLowerCase() == "orderbooks") {
      let new_entry = JSON.parse(data.data);
      // "{\"side\":\"Bid\",\"price\":0.1543,\"amount\":0.12}"

      // toast.info(`New ${new_entry.side} order at ${new_entry.price} with amount ${new_entry.amount}`)

      if (new_entry.side == "Bid") {
        setBids((prev) => {
          let new_orders = [...prev];
          let exists = new_orders.find((el) => el.price == new_entry.price);
          if (new_entry.amount == 0) {
            new_orders = new_orders.filter((el) => el.price != new_entry.price);
            new_orders.sort((a, b) => b.price - a.price);
            return new_orders;
          }

          if (exists) {
            let price = new Decimal(new_entry.price);
            let amount = new Decimal(new_entry.amount);
            let total = price.times(amount).toNumber();
            exists.amount = new_entry.amount;
            exists.total = total;
          } else {
            let price = new Decimal(new_entry.price);
            let amount = new Decimal(new_entry.amount);
            let total = price.times(amount).toNumber();

            console.log("book event", total)
            new_orders.push({
              price: new_entry.price,
              amount: new_entry.amount,
              total: total,
            });
          }
          new_orders.sort((a, b) => b.price - a.price);
          return new_orders;
        })
      }

      if (new_entry.side == "Ask") {
        setAsks((prev) => {
          let new_orders = [...prev];
          let exists = new_orders.find((el) => el.price == new_entry.price);
          if (new_entry.amount == 0) {
            new_orders = new_orders.filter((el) => el.price != new_entry.price);
            new_orders.sort((a, b) => a.price - b.price);
            return new_orders;
          }

          if (exists) {
            let price = new Decimal(new_entry.price);
            let amount = new Decimal(new_entry.amount);
            let total = price.times(amount).toNumber();
            exists.amount = new_entry.amount;
            exists.total = total;
          } else {

            let price = new Decimal(new_entry.price);
            let amount = new Decimal(new_entry.amount);
            let total = price.times(amount).toNumber();
            new_orders.push({
              price: new_entry.price,
              amount: new_entry.amount,
              total: total,
            });
          }

          new_orders.sort((a, b) => a.price - b.price);
          return new_orders;
        });
      }

    }

    // if (data.channel.toLowerCase() === "marketdata") {
    //   if (data.topic.toLowerCase() === "orderbook") {
    //     if (data.reset == true) {
    //       resetHandler(data);
    //     } else {
    //       incrementHandler(data);
    //     }
    //   }

    //   if (data.topic == "tickers") {
    //     if (data?.ticker?.market_id != pair) {
    //       return;
    //     }
    //     if (data.reset === true) {
    //       setTickers(data?.ticker);
    //     } else {
    //       setTickers(data?.data);
    //     }
    //   }

    //   // if (data.topic === "trades") {
    //   //   if (data.reset === true) {
    //   //     let trades = data?.trades;
    //   //     let filtered = trades.filter((trade) => trade.pair == pair);
    //   //     setRecentTrades(filtered);
    //   //   } else {
    //   //     let trades = data?.data;
    //   //     setRecentTrades((prev) => {
    //   //       let new_trades = [...prev, ...trades];
    //   //       // filter out where pair is not equal to current pair

    //   //       let filtered = new_trades.filter(
    //   //         (new_trade) => new_trade.pair == pair
    //   //       );
    //   //       return filtered;
    //   //     });
    //   //   }
    //   // }
    // }
  }
  async function orderBookresetHandler(data) {
    let bids = data.bids;
    let asks = data.asks;

    // highest bids first
    bids.sort((a, b) => b.price - a.price);
    asks.sort((a, b) => a.price - b.price);

    // loop through bids and asks and set the amount and total
    bids.forEach((bid) => {
      let price = new Decimal(bid.price);
      let amount = new Decimal(bid.amount);
      let total = price.times(amount).toNumber();
      bid.total = total;
    });
    asks.forEach((bid) => {
      let price = new Decimal(bid.price);
      let amount = new Decimal(bid.amount);
      let total = price.times(amount).toNumber();
      bid.total = total;
    });

    setBids(bids);
    setAsks(asks);
  }
  async function tickerHandler(event) {
    let data = JSON.parse(event.data);
    // if (data.market_id != pair) {
    //   return;
    // }

    if (data?.channel?.toLowerCase() != "tickers") {
      return;
    }


    if (data?.data && data?.reset === true) {
      let tickers = data?.data?.find((ticker) => ticker?.pair == pair?.toLowerCase());
      setTickers(tickers);
      return;
    }


    if (data?.channel == "Tickers") {
      let tickers = data?.data;

      // turn string to array
      let ticker_json = JSON.parse(tickers);
      console.log("tickers datassse 1", ticker_json)
      console.log("tickers datassse", typeof (tickers))

      let filtered_ticker = ticker_json.find((ticker) => ticker.pair == pair);

      setTickers(filtered_ticker);
    }

    // let ticker_json = JSON.parse(ticker);
    // console.log("tickers datasss", tickers)


    // for (let i = 0; i < ticker_json.length; i++) {
    //   let ticker = ticker_json[i];
    //   if (ticker.pair == pair) {
    //     // setTickers((prev) => {
    //     //   let new_tickers = [...prev];
    //     //   new_tickers.push(ticker);
    //     //   return new_tickers;
    //     // });
    //   }
    // }
  }
  // async function incrementHandler(data) {
  //   if (data.pair != pair) {
  //     console.error("Log error");
  //     return;
  //   }

  //   if (data.data.length == 0) {
  //     return;
  //   }
  //   data.data.forEach((order) => {
  //     let price = order.price;
  //     let quantity = order.quantity;
  //     let total = order.total;

  //     if (order.side == "Bid") {
  //       setBids((prev) => {
  //         let new_orders = [...prev];
  //         let exists = new_orders.find((el) => el.price == price);
  //         if (quantity == 0) {
  //           new_orders = new_orders.filter((el) => el.price != price);
  //           new_orders.sort((a, b) => b.price - a.price);
  //           return new_orders;
  //         }

  //         if (exists) {
  //           exists.amount = quantity;
  //           exists.total = total;
  //         } else {
  //           new_orders.push({
  //             price,
  //             amount: quantity,
  //             total,
  //           });
  //         }
  //         new_orders.sort((a, b) => b.price - a.price);
  //         return new_orders;
  //       });
  //     }

  //     if (order.side == "Ask") {
  //       setAsks((prev) => {
  //         let new_orders = [...prev];
  //         let exists = new_orders.find((el) => el.price == price);
  //         if (quantity == 0) {
  //           new_orders = new_orders.filter((el) => el.price != price);
  //           new_orders.sort((a, b) => a.price - b.price);
  //           return new_orders;
  //         }

  //         if (exists) {
  //           exists.amount = quantity;
  //           exists.total = total;
  //         } else {
  //           new_orders.push({
  //             price,
  //             amount: quantity,
  //             total,
  //           });
  //         }

  //         new_orders.sort((a, b) => a.price - b.price);
  //         return new_orders;
  //       });
  //     }
  //   });
  // }

  async function pushHistory(event) {
    let data = JSON.parse(event.data);
    console.log("History ALpo", data)
    // if (data.channel.toLowerCase() === "orders") {
    //   if (data.topic === "open_orders") {
    //     if (data.data) {
    //       // setOpenOrders(prevOrders => [...prevOrders, ...data.data]);
    //       let initial_quantity = data.data.initial_quantity / Math.pow(10, 8);
    //       let executed_quantity = data.data.executed_quantity / Math.pow(10, 8);
    //       let total = (data.data.price / Math.pow(10, 8)) * initial_quantity;

    //       let status_of_order = data.data.status;
    //       if (status_of_order.toLowerCase() == "cancelled") {

    //         console.log(openOrders)
    //         let new_orders = openOrders.filter(
    //           (order) => order.order_id != data.data.order_id
    //         );
    //         console.log("Removing order", data.data);

    //         setOpenOrders(new_orders);
    //         console.log(new_orders)
    //         return;
    //       }

    //       let new_order = {
    //         ...data.data,
    //         price: data.data.price / Math.pow(10, 8),
    //         amount: initial_quantity - executed_quantity,
    //         initial_quantity: initial_quantity,
    //         executed_quantity: executed_quantity,
    //         total: total,
    //         type: data.data.order_type,
    //       };

    //       setOpenOrders((prevOrders) => {
    //         // just push the new order
    //         let new_orders = [...prevOrders, new_order];
    //         let sorted_by_highest_timestamp = new_orders.sort(
    //           (a, b) => b.timestamp - a.timestamp
    //         );
    //         return sorted_by_highest_timestamp;
    //       });
    //     }

    //     // push data
    //     if (data.reset === true) {
    //       console.log(data.orders)
    //       setOpenOrders(data.orders);
    //     }
    //   }

    //   if (data.topic === "trade_history") {
    //     if (data.reset === true) {
    //       setTradeHistory(data.trades);
    //     }
    //   }

    //   if (data.topic === "order_history") {
    //     if (data.reset === true) {
    //       setOrderHistory(data.orders);
    //     }
    //   }
    // }
  }

  async function pushTradesHistory(event) {
    let data = JSON.parse(event.data);


    console.log("ssssssss", data);
    if (data?.channel?.toLowerCase() === "trades") {
      if (data.reset === true) {
        let trades = data?.data;
        // sort by timestamp
        trades.sort((a, b) => b.timestamp - a.timestamp);
        setRecentTrades(trades);
        return;
      }

      let trades = data?.data;
      let json_trades = JSON.parse(trades);
      console.log("tradesssss", json_trades)

      setRecentTrades((prev) => {
        let new_trades = [...prev];
        new_trades.push(json_trades);
        // filter out where pair is not equal to current pair
        let filtered = new_trades.filter(
          (new_trade) => new_trade.pair == pair
        );
        return filtered;
      });
    }
  }

  // useEffect(() => {
  //   fetchBookFromWs();
  // }, [pair]);

  const tabs = ["Chart", "Order Book", "Recent Trd.", "Pairs"];

  const [activeTab, setActiveTab] = useState("Chart");
  const polygonIoApiKey = "IR3qS2VjZ7kIDgnlqKxSmCRHqyBaMh9q";
  return (
    <>
      <div
        className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}
      >
        <Toolbar tickers={tickers} pair={pair} />
        <div className={classes.dekstopView}>
          <OrderBookRecentTrades
            pricePrecision={pricePrecision}
            amountPrecision={amountPrecision}
            bids={bids}
            asks={asks}
            tickers={tickers}
            market={market}
            bidSideAmount={bidSideAmount}
            setAskSideAmount={setAskSideAmount}
            askSideAmount={askSideAmount}
            setBidSideAmount={setBidSideAmount}
            setPrice={setPrice}
          />

          {/* <div className={classes.middle}>
            <TradingChart />
            <BuySell askSideAmount={askSideAmount} pair={pair} />
          </div> */}
          <div className={classes.right}>
            <div className={classes.chartAndPairs}>
              {/* <TVChartContainer pair={pair} /> */}
              <TradingChart polygonIoApiKey={polygonIoApiKey} pair={pair} />
              <Pairs setPair={setPair} tickers={tickers} />
            </div>
            <div className={classes.buySellAndTrading}>
              <BuySell
                pair={pair}
                price={price}
                setPrice={setPrice}
                market={market}
                bidSideAmount={bidSideAmount}
                setAskSideAmount={setAskSideAmount}
                askSideAmount={askSideAmount}
                setBidSideAmount={setBidSideAmount}
              />
              <Trades recentTrades={recentTrades} market={market} />
            </div>
          </div>
        </div>
        <div className={classes.mobileComponents}>
          <div className={classes.tabs}>
            {tabs.map((tab, i) => (
              <p
                className={clsx(
                  classes.tab,
                  tab.toLowerCase() === activeTab.toLowerCase() &&
                  classes.activeTab
                )}
                key={i}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </p>
            ))}
          </div>{" "}
          <div className={classes.line}></div>
          {/* {activeTab === "Chart" && <TVChartContainer pair={pair} />} */} *{" "}
          {activeTab === "Chart" && (
            <TradingChart polygonIoApiKey={polygonIoApiKey} pair={pair} />
          )}
          {activeTab === "Order Book" && (
            <OrderBook
              pricePrecision={pricePrecision}
              amountPrecision={amountPrecision}
              setPrice={setPrice}
              bids={bids}
              asks={asks}
              tickers={tickers}
              market={market}
              bidSideAmount={bidSideAmount}
              setAskSideAmount={setAskSideAmount}
              askSideAmount={askSideAmount}
              setBidSideAmount={setBidSideAmount}
            />
          )}
          {activeTab === "Recent Trd." && (
            <Trades recentTrades={recentTrades} market={market} />
          )}
          {activeTab === "Pairs" && <Pairs setPair={setPair} />}
        </div>
        <div className={classes.tablet}>
          <BuySell
            price={price}
            setPrice={setPrice}
            pair={pair}
            bidSideAmount={bidSideAmount}
            setAskSideAmount={setAskSideAmount}
            askSideAmount={askSideAmount}
            setBidSideAmount={setBidSideAmount}
          />
        </div>
        <MobileLayoutBuySell
          price={price}
          setPrice={setPrice}
          pair={pair}
          bidSideAmount={bidSideAmount}
          setAskSideAmount={setAskSideAmount}
          askSideAmount={askSideAmount}
          setBidSideAmount={setBidSideAmount}
        />
        <History
          pair={pair}
          setOpenOrders={setOpenOrders}
          openOrders={openOrders}
          tradeHistory={tradeHistory}
          orderHistory={orderHistory}
        />
      </div>

      {/* <MobileLayoutBuySell /> */}
    </>
  );
};

export default SportPage;
