import Section from "components/common/Section/Section";
import PageHeading from "components/common/PageHeading/PageHeading";
import Navigator from "components/common/Navigator/Navigator";
import Input from "components/common/Input/Input";
import Button from "components/Button/Button";
import classes from "./SetPassword.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SetPassword = ({ title, is_new }) => {
  let navigate = useNavigate();
  const [formState, setFormState] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [loginData, setLoginData] = useState({});




  async function updatePasswordToLocalStorage(e) {
    e.preventDefault();


    if (!is_new) {
      if (!passwordMatch) {
        alert("Passwords do not match!");
        return;
      };

      let newLoginData = { ...loginData, password: formState.password };
      localStorage.setItem("signup-details", JSON.stringify(newLoginData));

      let api = process.env.REACT_APP_API;
      let route = "/account/init_signup";
      let url = api + route;

      let body = newLoginData;

      try {
        let { data, status } = await axios.post(url, body);

        if (status === 200) {
          // localStorage.removeItem("signup-details");
          navigate("/signup-verify");
        }
      } catch (e) {
        let msg = e.response.data.message;
        alert(msg);
      }
    } else {

      // password change

      if (!passwordMatch) {
        alert("Passwords do not match!");
        return;
      }

      let pass_reset_data = localStorage.getItem("resetPassword");
      pass_reset_data = JSON.parse(pass_reset_data);
      let new_pass_reset_data = { ...pass_reset_data, new_password: formState.password };
      localStorage.setItem("resetPassword", JSON.stringify(new_pass_reset_data));

      navigate("/verify-password-reset");





    }


  }

  async function getSignupData() {
    let loginData = localStorage.getItem("signup-details");
    loginData = JSON.parse(loginData);
    setLoginData(loginData);
    return loginData;
  }

  useEffect(() => {
    getSignupData();
  }, []);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    if (formState.password !== "" && formState.confirmPassword !== "")
      setPasswordMatch(formState.password === formState.confirmPassword);
    else if (formState.password !== "" && formState.confirmPassword === "")
      setPasswordMatch(false);
    else setPasswordMatch(true);
  }, [formState]);

  return (
    <Section withPadding xShort className={classes.setPassword}>
      <PageHeading
        heading={title}
        subHeading="Enter a strong password to make your account more secure. Don’t use common password."
      />

      <form action="" className={classes.form}>
        <Input
          hasError={!passwordMatch}
          placeholder="New Password"
          type="password"
          name="password"
          value={formState.password}
          onChange={inputChangeHandler}
        />
        <Input
          hasError={!passwordMatch}
          helperError={!passwordMatch && "Password is not matched!"}
          placeholder="Confirm New Password"
          type="password"
          name="confirmPassword"
          value={formState.confirmPassword}
          onChange={inputChangeHandler}
        />
        <Button
          onClick={updatePasswordToLocalStorage}
          className={classes.btn}
          btnPrimary
          size="md"
        >
          Save Password
        </Button>
      </form>

      <Navigator to="/login">Back to Login</Navigator>
    </Section>
  );
};

export default SetPassword;
