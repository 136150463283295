import React from "react";

import classes from "./MarketsTable.module.css";
import clsx from "clsx";
import Button from "components/Button/Button";

const SingleRow = ({
  base_asset,
  quote_asset,
  symbol,
  last,
  change,
  currency,
  pair,
}) => {

  let quote_currency = currency?.find(
    (el) => el.id == quote_asset?.toLowerCase()
  );
  let base_currency = currency?.find(
    (el) => el.id == base_asset?.toLowerCase()
  );

  let base_logo = base_currency?.logo;
  let quote_logo = quote_currency?.logo;

  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>
          <div className={classes.logoAndNameContainer}>
            <img
              src={base_logo}
              alt="#"
              className={classes.logo}
            />
            <div className={classes.nameContainer}>
              <p>{base_asset?.toUpperCase() + "/" + quote_asset?.toUpperCase()}</p>
              <span>{symbol}</span>
            </div>
          </div>
        </td>

        <td className={classes.item}>{"$" + last?.toFixed(2)}</td>
        <td
          className={[
            classes.item,

            change > 0
              ? clsx(classes.positiveAmount, classes.amount)
              : clsx(classes.negativeAmount, classes.amount),
          ].join(" ")}
        >
          {change > 0 ? "+" : "-"}
          {Math.abs((Number(change)?.toFixed(2)))}%
        </td>
        <td className={classes.item}>
          <div className={classes.buttonContainer}>
            <Button btnTransparent onClick={() => {
              //  window.history.pushState({}, "", `/spot/${symbol}`);
              window.location.href = `/spot/${pair}`;
            }}>
              Trade
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
