import React, { useEffect, useRef, useState } from "react";
import { IoArrowDownSharp } from "react-icons/io5";
import Button from "components/Button/Button";
import clsx from "clsx";
import Modal from "components/common/Modal/Modal";
import Heading from "components/common/Heading/Heading";
import Input from "components/common/Input/Input";
import { checkIfNumber } from "components/utils/isNumber";
import useOnClickOutside from "hooks/useOnClickOutside";
import Dropdown from "../Dropdown/Dropdown";
import { bitcoin, earn, spot, tether } from "assets";
import classes from "./TransferModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { fetchBalance } from "../../../redux/balance";
import { UseDispatch } from "react-redux";
import { useTheme } from "ThemeContext/ThemeContext";
import { toast } from "react-toastify";

const walletsItems = [
  {
    logo: earn,
    name: "Funding",
  },
  {
    logo: spot,
    name: "Spot",
  },
  {
    logo: spot,
    name: "Staking",
  },
];
const TransferModal = ({
  isActive,
  onClose,
  setIsConfirmationModal,
  defaultCurrency,
}) => {
  const { isDarkTheme } = useTheme();
  const [amount, setAmount] = useState("");
  let currencies = useSelector((state) => state.currencies.currencies);
  let balances = useSelector((state) => state.balances.balances);

  let dispatch = useDispatch();

  //currency dropdown state
  const currencyDropdownRef = useRef(null);
  const [isCurrencyDropdownActive, setIsCurrencyDropdownActive] =
    useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);
  useOnClickOutside(currencyDropdownRef, () =>
    setIsCurrencyDropdownActive(false)
  );

  async function process_transfer() {
    let api = process.env.REACT_APP_API;
    let route = "/balance/self-transfer";

    let body = {
      from: selectedFromWalletValue.name,
      to: selectedToWalletValue.name,
      amount: Number(amount),
      currency: selectedCurrency.id,
    };

    let { data, status } = await axios.post(api + route, body, {
      headers: {
        Authorization: `Bearer ${Cookies.get("auth-token")}`,
      },
    });

    if (status == 200) {
      dispatch(fetchBalance());
      toast.success("Transfer Successful", {
        position: "top-center",
        theme: "dark",
      });
      onClose();
    } else {
    }
  }

  const [balance, setBalance] = useState(0);

  async function calcBalance() {
    if (!selectedCurrency) return;

    let balance = balances.find(
      (el) =>
        el.currency === selectedCurrency.id &&
        el.wallet_type === selectedFromWalletValue.name
    );
    setBalance(balance?.balance);
  }

  async function setDefaultCurrency() {
    if (defaultCurrency) {
      let def = currencies.find((el) => el.id === defaultCurrency);
      setSelectedCurrency(def);
    }
  }

  useEffect(() => {
    setDefaultCurrency();
  }, [defaultCurrency]);

  //from dropdown state

  const fromDropdownRef = useRef(null);
  const [isFromDropdownActive, setIsFromDropdownActive] = useState(false);
  const [selectedFromWalletValue, setSelectedFromWalletValue] = useState(
    walletsItems[0]
  );
  useOnClickOutside(fromDropdownRef, () => setIsFromDropdownActive(false));

  //to dropdown state

  useEffect(() => {
    calcBalance();
  }, [selectedCurrency, selectedFromWalletValue]);

  const toDropdownRef = useRef(null);
  const [isToDropdownActive, setIsToDropdownActive] = useState(false);
  const [selectedToWalletValue, setSelectedToWalletValue] = useState(
    walletsItems[1]
  );
  useOnClickOutside(toDropdownRef, () => setIsToDropdownActive(false));
  return (
    <Modal
      className={clsx(
        classes.wrapper,
        "overflow",
        !isDarkTheme && classes.lightTheme
      )}
      isActive={isActive}
      onClose={onClose}
      styled
    >
      <Heading heading="Intra Wallet Transfer" wFull />
      <div className={classes.headingContainer}>
        {/* <Heading heading="Amount" center lg /> */}
        {/* <p className={classes.text}>
          Enter your an amount that you want to send.
        </p>{" "} */}
      </div>

      <div className={classes.fromTo}>
        <div className={classes.inputContainer}>
          <p className={classes.label}>From</p>
          <div ref={fromDropdownRef} className={classes.dropdownContainer}>
            <Dropdown
              isActive={isFromDropdownActive}
              selectedValue={selectedFromWalletValue}
              dropdownItems={walletsItems}
              onClick={() => {
                if (isFromDropdownActive) {
                  setIsFromDropdownActive(false);
                } else {
                  setIsFromDropdownActive(true);
                }
              }}
              isDropdownActive={isFromDropdownActive}
              onSelect={(val) => {
                setSelectedFromWalletValue(val);
                setIsFromDropdownActive(false);
              }}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <p className={classes.label}>To</p>
          <div ref={toDropdownRef} className={classes.dropdownContainer}>
            <Dropdown
              isActive={isToDropdownActive}
              selectedValue={selectedToWalletValue}
              dropdownItems={walletsItems}
              onClick={() => {
                if (isToDropdownActive) {
                  setIsToDropdownActive(false);
                } else {
                  setIsToDropdownActive(true);
                }
              }}
              isDropdownActive={isToDropdownActive}
              onSelect={(val) => {
                setSelectedToWalletValue(val);
                setIsToDropdownActive(false);
              }}
            />
          </div>
          <div className={classes.arrowContainer}>
            <IoArrowDownSharp className={classes.toArrow} />
          </div>
        </div>
      </div>
      <div className={classes.currencyAmount}>
        {" "}
        <div className={classes.inputContainer}>
          <p className={classes.label}>Currency</p>
          <div ref={currencyDropdownRef} className={classes.dropdownContainer}>
            <Dropdown
              isActive={isCurrencyDropdownActive}
              selectedValue={selectedCurrency}
              dropdownItems={currencies}
              onClick={() => {
                if (isCurrencyDropdownActive) {
                  setIsCurrencyDropdownActive(false);
                } else {
                  setIsCurrencyDropdownActive(true);
                }
              }}
              isDropdownActive={isCurrencyDropdownActive}
              onSelect={(val) => {
                setSelectedCurrency(val);
                setIsCurrencyDropdownActive(false);
              }}
            />
          </div>
        </div>
        <p className={classes.balance}>
          {" "}
          Balance: {balance} {selectedCurrency?.symbol.toUpperCase()}
        </p>
        {/* add balance allign to right */}
        <div className={classes.inputContainer}>
          <p className={classes.label}>Amount</p>
          <Input
            rootClassName={classes.input}
            onKeyDown={(event) => checkIfNumber(event)}
            type="number"
            placeholder="0.00"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </div>
      </div>

      <div className={classes.buttonContainer}>
        {" "}
        <Button btnGray onClick={onClose} size="md">
          Cancel
        </Button>
        <Button
          btnPrimary
          onClick={() => {
            if (!amount) {
              return;
            }

            if (selectedFromWalletValue.name === selectedToWalletValue.name) {
              alert("Cant transfer to same wallet");
              return;
            }

            if (amount > balance) {
              return;
            }

            if (amount == 0) {
              alert("Cant transfer 0 amount");
              return;
            }

            // setIsConfirmationModal(true);
            // onClose();
            process_transfer();
          }}
          size="md"
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default TransferModal;
