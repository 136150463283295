import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'


export const getTransactions = createAsyncThunk("fetch-user-transactions", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API
        let route = "/transactions"
        let { data } = await axios.get(base_url + route, {
            headers: {
                "Authorization": "Bearer " + Cookies.get("auth-token")
            }
        })

        console.log(data, "transactions")
        let info = {
            transactions: data
        };
        return info
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const userInfoSlice = createSlice({
    name: 'stakingInstances',
    initialState: {
        transactions: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(getTransactions.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(getTransactions.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.transactions = action.payload.transactions
        })
        builder.addCase(getTransactions.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default userInfoSlice.reducer