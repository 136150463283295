import React, { useEffect } from "react";
import classes from "./Dashboard.module.css";
import BalanceContainer from "components/Dashboard/BalanceContainer/BalanceContainer";
import IdentityVerification from "components/Dashboard/IdentityVerification/IdentityVerification";
import InviteFriends from "components/Dashboard/InviteFriends/InviteFriends";
import MarketsTable from "components/Dashboard/Markets/MarketsTable";
import Announcement from "components/Dashboard/Announcement/Announcement";
import clsx from "clsx";
import TopBar from "components/Dashboard/TopBar/TopBar";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrencyPrices } from "../../redux/currencies";

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  console.log(user);
  let balance = useSelector((state) => state.balances);
  let prices = useSelector(selectCurrencyPrices);

  const [totalBalance, setTotalBalance] = React.useState(0);

  async function calculate_total_balance() {
    let balances = balance.balances;
    let valid_balances = balances.filter(
      (balance) => balance.wallet_type != "PaperTrading"
    );
    let total_balance = 0;
    for (let i = 0; i < valid_balances.length; i++) {
      //   {
      //     "token": "eth",
      //     "price": 2
      // }
      let balance = valid_balances[i];

      let price = prices.find((price) => price.token == balance.currency);
      if (price != 0 && balance.balance != 0) {
        let bal_to_add = balance.balance;
        let price_to_add = price.price;

        let final_balance = bal_to_add * price_to_add;
        total_balance += final_balance;
        setTotalBalance(total_balance);
      }
    }
  }

  useEffect(() => {
    calculate_total_balance();
  }, [prices, balance]);

  return (
    <div className={clsx("container", classes.wrapper)}>
      <TopBar user={user.userInfo} />
      <div className={classes.container}>
        <div className={classes.left}>
          {/* <BalanceContainer totalBalance={totalBalance} /> */}
          {<IdentityVerification is_verified={user?.userInfo?.kyc_level} />}
          <InviteFriends user={user.userInfo} />
        </div>
        <div className={classes.right}>
          <MarketsTable is_verified={user?.userInfo?.kyc_level} />
        </div>
      </div>{" "}
      {/* <Announcement /> */}
    </div>
  );
};

export default Dashboard;
