import React, { useEffect, useRef, useState } from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";

import { earn, more2, nexo, spot, tether } from "assets";
import useOnClickOutside from "hooks/useOnClickOutside";
import Dropdown from "../../../Dropdown/Dropdown";
import SpotDetailsModal from "modals/SpotDetailsModal/SpotDetailsModal";
import { useSelector } from "react-redux";
import { selectCurrencyPrices } from "../../../../../redux/currencies";
import { useNavigate } from "react-router-dom";
import Decimal from "decimal.js";
const dropdownItems = [
  {
    label: "Earn",
  },
  {
    label: "Buy / Sell",
  },
  {
    label: "Deposit",
  },

  {
    label: "Withdraw",
  },
  {
    label: "Transfer",
  },
  {
    label: "Details",
  },
];
const details = {
  coin: { logo: tether, symbol: "USDT", name: "TetherUS" },
  amount: 0.0,
  usdAmount: 330659.06,
  available: 0,
  withdrawing: 0,
  spotOrders: 0,
  convertOrder: 0,
  frozen: 0,
  spot: {
    logo: spot,
    name: "Spot",
    currency: "USDT",
    usdAmount: 41845.8,
    growth: 4.97,
  },
  earn: {
    logo: earn,
    name: "Earn",
    currency: "USDT",

    growth: 4.97,
  },
};
const SingleRow = ({ tickers, openModalFromWallet, id, name, setIsSendModalActive, balances, setTotalWalletBalance }) => {
  console.log("tickerss balance", tickers);
  const [dropDown, setDropDown] = useState([]);
  let Navigate = useNavigate();
  let markets = useSelector((state) => state.markets.markets);

  // let market_exists = markets.find((market) => market.quote_asset.toLowerCase() == currency.toLowerCase() || market.base_asset.toLowerCase() == currency.toLowerCase())

  async function setDropItems() {
    if (name.toLowerCase() == "spot") {

      let spotBuilder = []

      spotBuilder.push({
        label: "Deposit",
        action: () => { Navigate("/wallet/crypto/deposit") }
      });
      spotBuilder.push({
        label: "Withdraw",
        action: () => { Navigate("/wallet/crypto/withdraw") }
      });

      spotBuilder.push({
        label: "Transfer",
        action: () => { openModalFromWallet(id) }
      })
      spotBuilder.push({
        label: process.env.REACT_APP_PLATFORM_ID + " Pay",
        action: () => { setIsSendModalActive(true) }
      })
      // if (market_exists) {
      //   spotBuilder.push({
      //     label: `Trade ${market_exists.base_asset}/${market_exists.quote_asset}`,
      //     action: () => {
      //       Navigate(`/spot/${market_exists.base_asset}${market_exists.quote_asset}`)
      //     }
      //   })
      // }

      setDropDown(spotBuilder)
    }

    // if (name.toLowerCase() == "staking") {

    //   let builder = []

    //   builder.push({
    //     label: "Deposit",
    //     action: () => { Navigate("/wallet/crypto/deposit") }
    //   });

    //   builder.push({
    //     label: "Transfer",
    //     action: () => { openModalFromWallet(currency) }
    //   })
    //   builder.push({
    //     label: process.env.REACT_APP_PLATFORM_ID + " Pay",
    //     action: () => { setIsSendModalActive(true) }
    //   })
    //   if (market_exists) {
    //     builder.push({
    //       label: `Trade ${market_exists.base_asset}/${market_exists.quote_asset}`,
    //       action: () => {
    //         alert("Trading not ready")
    //       }
    //     })
    //   }

    //   setDropDown(builder)


    // }

    // if (name.toLowerCase() == "funding") {
    //   let builder = []

    //   builder.push({
    //     label: "Deposit",
    //     action: () => { Navigate("/wallet/crypto/deposit") }
    //   });

    //   builder.push({
    //     label: process.env.REACT_APP_PLATFORM_ID + " Pay",
    //     action: () => { setIsSendModalActive(true) }
    //   })

    //   builder.push({
    //     label: "Transfer",
    //     action: () => {
    //       openModalFromWallet(currency)
    //     }
    //   })

    //   setDropDown(builder)
    // }

    // if (name.toLowerCase() == "papertrading") {
    //   setDropDown([
    //     {
    //       label: "Request Refil",
    //       action: null,
    //     }
    //   ])
    // }
  }

  useEffect(() => {
    setDropItems();
  }, [name])

  let balance = balances.find((b) => b.currency_id === id)?.available_balance;
  let frozen_balance = balances.find((b) => b.currency_id === id)?.frozen_balance;


  let currencies = useSelector((state) => state.currencies.currencies);
  let currencyData = currencies.find((c) => c.id === id);

  let currency_price = useSelector(selectCurrencyPrices);
  let price = tickers?.find((t) => t.base_asset === id && t.quote_asset === "usdt");



  let total = 0.0;

  if (price) {
    let last_price = price?.last ? price?.last : 0;
    // let amount = new Decimal(balance + frozen_balance);
    // let price_id = new Decimal(last_price);
    // total = amount.times(price_id).toNumber()

    if (balance && frozen_balance) {
      total = new Decimal(frozen_balance).add(new Decimal(balance)).times(last_price).toNumber();
    }

    if (balance && !frozen_balance) {
      total = new Decimal(balance).times(last_price).toNumber();
    }
  }

  if (id == "usdt") {
    console.log(`pricessss balance: ${balance} frozen: ${frozen_balance}`)
    if (balance && frozen_balance) {
      total = new Decimal(frozen_balance).add(new Decimal(balance)).toNumber();
    }

    if (balance && !frozen_balance) {
      total = balance;
    }
  }
  // console.log("pricessss", id);
  if (id == "usdt" && balance && frozen_balance) {
    total = frozen_balance + balance;
  }





  const dropdownRef = useRef(null);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(dropdownItems[0].label);
  useOnClickOutside(dropdownRef, () => setIsDropdownActive(false));
  const [isModalActive, setIsModalActive] = useState(false);
  return (
    <>
      <tr>
        <td>
          {" "}
          <SpotDetailsModal
            isActive={isModalActive && selectedValue === "Details"}
            onClose={() => setIsModalActive(false)}
            {...details}
          />
        </td>
      </tr>


      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>
          <div className={classes.logoAndNameContainer}>
            <img src={currencyData?.logo} alt="#" className={classes.logo} />

            <div className={classes.nameContainer}>
              <p className={classes.symbol}>{currencyData?.name}</p>
              <span className={classes.name}>{currencyData?.symbol?.toUpperCase()}</span>
            </div>
          </div>
        </td>

        <td className={classes.item}>
          <div className={classes.priceContainer}>
            <p className={classes.price}>{(balance + frozen_balance) ? (balance + frozen_balance).toFixed(2) : 0.0}</p>
            <span>${total}</span>
          </div>
        </td>

        <td className={classes.item}>{balance ? balance?.toFixed(2) : 0.0}</td>
        <td className={classes.item}>{balance ? frozen_balance?.toFixed(2) : 0.0}</td>



        <td className={classes.item}>
          <div className={classes.buttonContainer}>
            <div
              className={classes.moreContainer}
              onClick={() => {
                if (isDropdownActive) {
                  setIsDropdownActive(false);
                } else {
                  setIsDropdownActive(true);
                }
              }}
            >
              <img src={more2} alt="#" className={classes.more} />
              {isDropdownActive && (
                <div ref={dropdownRef}>
                  <Dropdown
                    dontShowCurrenValue
                    isActive={isDropdownActive}
                    selectedValue={selectedValue}
                    dropdownItems={dropDown}
                    isDropdownActive={isDropdownActive}
                    onSelect={(val) => {
                      setSelectedValue(val);
                      setIsModalActive(true);
                      setIsDropdownActive(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
