import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'


export const getUserInfo = createAsyncThunk("fetch-user-info", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API
        let route = "/account/get_data"
        let data = await axios.get(base_url + route, {
            headers: {
                "Authorization": "Bearer " + Cookies.get("auth-token")
            }
        })

        console.log(data.data)
        let info = (data.data)
        return info
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const userInfoSlice = createSlice({
    name: 'stakingInstances',
    initialState: {
        userInfo: {},
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(getUserInfo.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(getUserInfo.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.userInfo = action.payload
        })
        builder.addCase(getUserInfo.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default userInfoSlice.reducer