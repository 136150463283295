import React, { useState } from "react";
import classes from "./Trades.module.css";
import clsx from "clsx";
import { useTheme } from "ThemeContext/ThemeContext";

const Trades = ({ recentTrades, market }) => {
  const { isDarkTheme } = useTheme();
  const tabs = ["Market Trades"];

  const [activeTab, setActiveTab] = useState("Market Trades");
  const data = [
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: -9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: -9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: -9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: -9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: -9.95, amount: 21.52, time: "14:02:13" },

    { price: -9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: -9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: -9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: -9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: -9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
    { price: 9.95, amount: 21.52, time: "14:02:13" },
  ];

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>


      {/* Put recent trades to be left alligned */}
      <p

      > Recent Trades</p>




      {/* <div className={classes.tabs}>
        {tabs.map((tab, i) => (
          <p
            className={clsx(
              classes.tab,
              tab.toLowerCase() === activeTab.toLowerCase() && classes.activeTab
            )}
            key={i}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </p>
        ))}
      </div> */}
      <div className={[classes.listWrapper].join(" ")}>
        <div className={[classes.listHeader].join(" ")}>
          <p className={[classes.heading, classes.textLeft].join(" ")}>
            Price({market?.quote_asset?.toUpperCase()})
          </p>
          <p className={[classes.heading, classes.textLeft].join(" ")}>
            Quantity({market?.base_asset?.toUpperCase()})
          </p>
          <p className={classes.heading}>Time</p>
        </div>

        <div className={[classes.listContainer, "overflow"].join(" ")}>
          {" "}
          {recentTrades
            .sort((a, b) => b.timestamp - a.timestamp)
            .map((el, i) => (
              <React.Fragment key={i}>
                <p
                  className={[
                    classes.price,
                    el.side == "Buy"
                      ? classes.positivePrice
                      : classes.negativePrice,
                  ].join(" ")}
                >
                  {el.price.toFixed(6)}
                </p>{" "}
                <p className={classes.amount}>{el.quantity?.toFixed(6)}</p>
                <p className={classes.total}>
                  {new Date(el.timestamp * 1000).toLocaleTimeString()}
                </p>
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};


export default Trades;
