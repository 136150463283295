import tickersReducer from "./tickers";
import marketsReducer from "./markets";
import balancesReducer from "./balance";
import currenciesReducer from "./currencies";
import userReducer from "./user";
import networksReducer from "./networks";
import stakingReducer from "./stakingInstances";
import transactionsReducer from "./external_transactions";
import rewardsReducer from "./reward";
import { configureStore } from "@reduxjs/toolkit";
export const store = configureStore({
    reducer: {
        tickers: tickersReducer,
        markets: marketsReducer,
        balances: balancesReducer,
        currencies: currenciesReducer,
        user: userReducer,
        networks: networksReducer,
        // stakingInstances: stakingReducer,
        transactions: transactionsReducer,
        rewards: rewardsReducer,
    },
});
