import React from "react";

import classes from "./SingleRow.module.css";
import clsx from "clsx";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SingleRow = ({ base_asset, quote_asset, symbol, last, change, currency }) => {

  let base_curr = currency.find((cur) => cur?.id == base_asset?.toLowerCase());
  let Navigate = useNavigate();
  return (
    <>
      <tr className={clsx(classes.row, classes.valueContainer)}>
        <td className={clsx(classes.item, classes.firstItem)}>
          <div className={classes.logoAndNameContainer}>
            <img src={base_curr?.logo} alt="#" className={classes.logo} />
            <div className={classes.nameContainer}>
              <p>{base_asset?.toUpperCase() + "/" + quote_asset?.toUpperCase()}</p>
              <span>{symbol}</span>
            </div>
          </div>
        </td>

        <td className={classes.item}>${last?.toFixed(2)}</td>
        <td
          className={[
            classes.item,

            change > 0
              ? clsx(classes.positiveAmount, classes.amount)
              : clsx(classes.negativeAmount, classes.amount),
          ].join(" ")}
        >
          {change > 0 ? "+" : "-"}
          {Math.abs(change?.toFixed(2))}%
        </td>
        <td className={classes.item}>{"--"}</td>
        <td className={classes.item}>
          <div className={classes.buttonContainer}>
            <Button btnTransparent onClick={() => {
              toast.info("Coming Soon");
            }}>
              Details
            </Button>
            <Button btnTransparent onClick={() => {
              Navigate(`/spot/${base_asset.toUpperCase()}${quote_asset.toUpperCase()}`);
            }}>
              Trade
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
