import React, { useRef, useState } from "react";
import { LuCopy } from "react-icons/lu";

import classes from "./SingleRow.module.css";
import clsx from "clsx";

import useOnClickOutside from "hooks/useOnClickOutside";

import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { useTheme } from "ThemeContext/ThemeContext";
import { toast } from "react-toastify";

const SingleRow = ({
  timestamp,
  id,
  type,
  wallet,
  network: deposit_network,
  id: transaction_hash,
  currency_id,
  direction,
  amount,
  destination,
  transaction_id,
  trxId,
  status,
  transaction_type,
}) => {
  const { isDarkTheme } = useTheme();
  let currencies = useSelector((state) => state.currencies.currencies);
  let currency = currencies?.find((curr) => curr.id == currency_id);

  let networks = useSelector((state) => state.networks.networks);
  let network = networks.find((network) => network.id == deposit_network);
  console.log(network)

  let explorer_link;

  if (deposit_network == "bsc") {
    explorer_link = "https://bscscan.com/tx/" + transaction_hash;
  }




  return (
    <>
      <tr
        className={clsx(
          classes.row,
          classes.valueContainer,
          !isDarkTheme && classes.lightTheme
        )}
      >
        <td className={clsx(classes.item, classes.firstItem)}>
          {new Date(timestamp * 1000).toLocaleString()}
        </td>
        <td className={clsx(classes.item)}>{transaction_type}</td>
        <td className={classes.item}>
          {deposit_network ? deposit_network?.toUpperCase() : "Internal"}
        </td>
        <td className={clsx(classes.item)}>{currency?.symbol?.toUpperCase()}</td>

        <td className={classes.item}>
          {amount?.toFixed(2)} {currency?.symbol?.toUpperCase()}
        </td>
        {/* <td className={classes.item}>
          <div className={classes.copyContainer}>
            <p className={classes.destination}>{destination}</p>
            <CopyToClipboard text={destination}>
                <div className={classes.copyIcon}>
                  <LuCopy />
                </div>
            </CopyToClipboard>
          </div>
        </td> */}
        <td className={classes.item}>
          <div className={classes.copyContianer}>
            <div className={classes.copyContainer}>
              {/* <p className={classes.trxId}>{transaction_hash ? transaction_hash : "internal tx"}</p> */}
              {transaction_id.length > 0 && <p className={classes.trxId}>
                {deposit_network
                  ? String(transaction_id).slice(0, 4) +
                  "..." +
                  String(transaction_id).slice(-4)
                  : "--"}
              </p>}

              {transaction_id.length == 0 && <p className={classes.trxId}>
                {/* Pending */}
              </p>}
              {explorer_link && transaction_id.length > 0 && <CopyToClipboard text={transaction_hash}>
                <div
                  onClick={() => {
                    toast.success("Copied to clipboard", {
                      position: "top-center",
                      autoClose: 300,

                    });
                  }}
                  className={classes.copyIcon}>
                  <LuCopy />
                </div>
              </CopyToClipboard  >}




              {explorer_link && transaction_id.length > 0 && <img
                onClick={() => {
                  window.open(explorer_link, "_blank");
                }}
                src="https://cdn-icons-png.flaticon.com/128/1017/1017466.png"
                alt=""
                className={classes.copyIcon}
              />}

            </div>
          </div>
        </td>

        <td
          className={clsx(
            classes.item,
            classes.lastItem,
            classes.status,
            status?.toLowerCase() === "completed" && classes.completed,
            status?.toLowerCase() === "pending" && classes.expired,
            status?.toLowerCase() === "canceled" && classes.canceled
          )}
        >
          {status}
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
