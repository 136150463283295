import Section from "components/common/Section/Section";
import PageHeading from "components/common/PageHeading/PageHeading";
import Button from "components/Button/Button";
import classes from "./PasswordChanged.module.css";
import { passwordChanged } from "assets";


export const UserCreated = ({ heading, content }) => {
  return (
    <Section withPadding xShort className={classes.passwordChanged}>
      <img className={classes.changed} src={passwordChanged} alt="tick" />

      <div className={classes.text}>
        <PageHeading
          heading={heading}
          subHeading={content}
        />
      </div>

      <Button to="/dashboard" className={classes.btn} btnPrimary size="md">
        Dashboard
      </Button>
    </Section>
  );
};
const PasswordChanged = ({ heading, content }) => {
  return (
    <Section withPadding xShort className={classes.passwordChanged}>
      <img className={classes.changed} src={passwordChanged} alt="tick" />

      <div className={classes.text}>
        <PageHeading
          heading={heading}
          subHeading={content}
        />
      </div>

      <Button to="/login" className={classes.btn} btnPrimary size="md">
        Back to Login
      </Button>
    </Section>
  );
};

export default PasswordChanged;
