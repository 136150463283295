import React, { useState } from "react";
import clsx from "clsx";
import ViewMore from "components/common/ViewMore/ViewMore";
import OpenOrders from "./OpenOrders/OpenOrders";
import classes from "./History.module.css";
import OpenOrderMobileVersion from "./OpenOrderMobileView/OpenOrderMobileView";
import OrderHistory from "./OrderHistory/OrderHistory";
import OrderHistoryMobileView from "./OrderHistoryMobileView/OrderHistoryMobileView";
import TradeHistory from "./TradeHistory/TradeHistory";
import TradeHistoryMobileview from "./TradeHistoryMobileview/TradeHistoryMobileview";
import { useNavigate } from "react-router-dom";
import { useTheme } from "ThemeContext/ThemeContext";

const openOrders = [
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    action: "",
  },
  // Add more data objects as needed
];
const orderHistory = [
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Expired",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Canceled",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Expired",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Canceled",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Completed",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Expired",
  },
  {
    time: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    unFilled: "0.00%",
    triggerCondition: "-",
    total: "8.791 USDT",
    status: "Canceled",
  },
  // Add more data objects as needed
];
const tradeHistory = [
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Buy",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  {
    date: "02-04 13:45:54",
    pair: "BTC/USDT",
    type: "Limit",
    side: "Sell",
    price: "0.880",
    amount: "4,729.9288 USDT",
    filled: "0.00%",
    total: "8.791 USDT",
    tradeInUsdt: "8.791 USDT",
  },
  // Add more data objects as needed
];
const History = ({
  pair,
  openOrders: open_orders,
  tradeHistory: trade_history,
  orderHistory,
  setOpenOrders,
}) => {
  const { isDarkTheme } = useTheme();
  const [activeTab, setActiveTab] = useState("Open Orders");
  let Navigate = useNavigate();

  console.log("history data", trade_history);

  return (
    <section
      className={clsx(classes.mainWrapper, !isDarkTheme && classes.lightTheme)}
    >
      {/* <h3 className={classes.heading}>{activeTab}</h3> */}
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.tabs}>
            <p
              className={clsx(
                classes.tab,
                "Open Orders" === activeTab && classes.activeTab
              )}
              onClick={() => setActiveTab("Open Orders")}
            >
              Open Ord<span>ers</span>
              <span className={classes.dot}>.</span>
              {activeTab === "Open Orders" && ``}
            </p>
            <p
              className={clsx(
                classes.tab,
                "Trade History" === activeTab && classes.activeTab
              )}
              onClick={() => setActiveTab("Trade History")}
            >
              Trade His<span>tory</span>
              <span className={classes.dot}>.</span>
              {activeTab === "Trade History" && ``}
            </p>
            <p
              className={clsx(
                classes.tab,
                "Order History" === activeTab && classes.activeTab
              )}
              onClick={() => setActiveTab("Order History")}
            >
              Order His<span>tory</span>
              <span className={classes.dot}>.</span>
              {activeTab === "Order History" && ``}
            </p>
          </div>
          <ViewMore
            text="View history"
            onClick={() => {
              Navigate("/history");
            }}
          />
        </div>{" "}
        <div className={classes.dekstopView}>
          {activeTab === "Open Orders" && (
            <OpenOrders pair={pair} setOpenOrders={setOpenOrders} data={open_orders?.sort((a, b) => b.timestamp - a.timestamp)} />
          )}
          {activeTab === "Trade History" && (
            <TradeHistory data={trade_history?.sort((a, b) => b?.timestamp - a?.timestamp)} />
          )}
          {activeTab === "Order History" && (
            <OrderHistory data={orderHistory.sort((a, b) => b.timestamp - a.timestamp)} />
          )}
        </div>
        <div className={classes.mobileView}>
          {activeTab === "Open Orders" && (
            <OpenOrderMobileVersion setOpenOrders={setOpenOrders} data={open_orders} />
          )}{" "}
          {activeTab === "Trade History" && (
            <TradeHistoryMobileview data={trade_history} />
          )}
          {activeTab === "Order History" && (
            <OrderHistoryMobileView data={orderHistory} />
          )}
        </div>{" "}
      </div>
    </section>
  );
};

export default History;
