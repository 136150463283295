import clsx from "clsx";
import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

import Dropdown from "./Dropdown/Dropdown";

import { address, tether } from "assets";
import classes from "./DepositCrypto.module.css";
import Button from "components/Button/Button";

import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import { useTheme } from "ThemeContext/ThemeContext";

const DepositCrypto = () => {
  const { isDarkTheme } = useTheme();
  const [step, setStep] = useState(1);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  const [wallet_address, setWallet] = useState("Generating wallet ....");
  const [full_add, setFull] = useState(false);

  const [selected_coin_networks, set_selected_coin_networks] = useState([]);
  let currencies = useSelector((state) => state.currencies.currencies);
  let networks = useSelector((state) => state.networks.networks);

  function get_coin_networks() {
    if (selectedCoin == null) {
      return;
    }
    let ids = [];
    selectedCoin.networks.map((network) => {
      ids.push(network.network_id);
    });

    let full_network = networks.filter((network) => {
      return ids.includes(network.id);
    });

    set_selected_coin_networks(full_network);
  }

  async function get_address() {
    if (step != 3) {
      return;
    }

    if (!selectedNetwork?.protocol) {
      return;
    }
    let api = process.env.REACT_APP_API;
    // let route =
    //   "/deposit/address/" + selectedNetwork.protocol.toLowerCase();

    let query_params = {
      wallet_type: "Evm",
    }
    let route = "/deposit/address" + "?" + new URLSearchParams(query_params).toString();

    try {
      let { data } = await axios.get(api + route, {
        headers: {
          Authorization: "Bearer " + Cookies.get("auth-token"),
        },
      });

      if (data.address) {
        setWallet(data.address);
      }
    } catch (e) {
      setWallet("Something went wrong");
    }
  }

  useEffect(() => {
    get_coin_networks();
  }, [selectedCoin]);

  useEffect(() => {
    get_address();
  }, [step]);

  return (
    <div className={clsx(classes.wrapper, !isDarkTheme && classes.lightTheme)}>
      <div className={clsx(classes.stepItem)}>
        <div className={classes.labelContainer}>
          {step >= 2 ? (
            <div
              className={clsx(classes.number, step >= 2 && classes.completed)}
            >
              <FaCheck className={classes.checkMark} />
            </div>
          ) : (
            <div className={clsx(classes.number)}>1</div>
          )}
        </div>
        <div className={classes.inputsContainer}>
          {step >= 1 && (
            <Dropdown
              selectedOption={selectedCoin}
              setSelectedOption={setSelectedCoin}
              uniqueKey="select-coin"
              pattern1
              placeholder="Select Coin"
              options={currencies}
              onSelect={(val) => {
                setStep(2);
                setSelectedCoin(val);
                setSelectedNetwork(null);
              }}
            />
          )}
        </div>
      </div>
      <div className={clsx(classes.stepItem)}>
        {step >= 2 && (
          <div className={classes.labelContainer}>
            {step >= 3 ? (
              <div className={clsx(classes.number, classes.completed)}>
                <FaCheck className={classes.checkMark} />
              </div>
            ) : (
              <div className={clsx(classes.number)}>2</div>
            )}
          </div>
        )}

        <div className={classes.inputsContainer}>
          {step >= 2 && (
            <Dropdown
              selectedOption={selectedNetwork}
              setSelectedOption={setSelectedNetwork}
              uniqueKey="select-network"
              placeholder="Select Network"
              options={selected_coin_networks}
              onSelect={(val) => {
                setStep(3);
              }}
            />
          )}
        </div>
      </div>
      <div className={clsx(classes.stepItem)}>
        {step >= 3 && (
          <div className={classes.labelContainer}>
            {step >= 4 ? (
              <div className={clsx(classes.number, classes.completed)}>
                <FaCheck className={classes.checkMark} />
              </div>
            ) : (
              <div className={clsx(classes.number)}>3</div>
            )}
          </div>
        )}

        <div
          className={[classes.inputsContainer, classes.addressWrapper].join(
            " "
          )}
        >
          {step >= 3 && (
            <div className={classes.addressContainer}>
              {" "}
              <p className={classes.addressLabel}>Deposit Address</p>
              <div className={classes.addressWrapper}>
                <img
                  width={150}
                  src={`https://api.qrserver.com/v1/create-qr-code/?data=${wallet_address}&amp;size=200x200`}
                  alt="address"
                />
                <div className={classes.addressCopyContainer}>
                  <small
                    onClick={() => {
                      setFull(!full_add);
                    }}
                    className={classes.address}
                  >
                    {!full_add
                      ? wallet_address.substring(0, 5) +
                      "....." +
                      wallet_address.slice(-8)
                      : wallet_address}
                  </small>
                  <Button
                    btnPrimary
                    size="sm"
                    onClick={() => {
                      window.navigator.clipboard.writeText(wallet_address);
                    }}
                  >
                    Copy
                  </Button>
                </div>
              </div>
              <div className={classes.bottomBar}>
                {/* <p>Deposit to</p> */}
                {/* <p className={classes.text}>Funding Wallet</p> */}
                {/* <Button btnTransparent>Edit</Button> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositCrypto;

//

// import clsx from "clsx";
// import { useEffect, useState } from "react";

// import Dropdown from "./Dropdown/Dropdown";

// import { address } from "assets";
// import classes from "./DepositCrypto.module.css";
// import Button from "components/Button/Button";

// import { useSelector } from "react-redux";
// import axios from "axios";
// import Cookies from "js-cookie";

// const DepositCrypto = () => {
//   const [step, setStep] = useState(1);
//   const [selectedCoin, setSelectedCoin] = useState(null);
//   const [selectedNetwork, setSelectedNetwork] = useState(null);

//   const [wallet_address, setWallet] = useState("Generating wallet ....");
//   const [full_add, setFull] = useState(false);

//   const [selected_coin_networks, set_selected_coin_networks] = useState([]);
//   let currencies = useSelector((state) => state.currencies.currencies);
//   let networks = useSelector((state) => state.networks.networks);

//   function get_coin_networks() {
//     if (selectedCoin == null) {
//       return;
//     }
//     let ids = [];
//     selectedCoin.networks.map((network) => {
//       ids.push(network.network_id);
//     });

//     let full_network = networks.filter((network) => {
//       return ids.includes(network.id);
//     });

//     set_selected_coin_networks(full_network);
//   }

//   async function get_address() {
//     if (step != 3) {
//       return;
//     }

//     if (!selectedNetwork?.protocol) {
//       return;
//     }
//     let api = process.env.REACT_APP_API;
//     let route =
//       "/addresses/address/create/" + selectedNetwork.protocol.toLowerCase();

//     try {
//       let { data } = await axios.get(api + route, {
//         headers: {
//           Authorization: "Bearer " + Cookies.get("auth-token"),
//         },
//       });

//       if (data.address) {
//         setWallet(data.address);
//       }
//     } catch (e) {
//       setWallet("Something went wrong");
//     }
//   }

//   useEffect(() => {
//     get_coin_networks();
//   }, [selectedCoin]);

//   useEffect(() => {
//     get_address();
//   }, [step]);

//   return (
//     <div className={classes.wrapper}>
//       <div className={clsx(classes.stepItem, step >= 1 && classes.active)}>
//         <div className={classes.labelContainer}>
//           <div className={classes.number}>
//             1 <div className={classes.dottedLine}></div>
//           </div>
//           <div className={classes.label}>Select Coin</div>
//         </div>
//         <div className={classes.inputsContainer}>
//           {step >= 1 && (
//             <Dropdown
//               selectedOption={selectedCoin}
//               setSelectedOption={setSelectedCoin}
//               uniqueKey="select-coin"
//               pattern1
//               placeholder="Select Coin"
//               options={currencies}
//               onSelect={(val) => {
//                 setStep(2);
//                 setSelectedCoin(val);
//                 setSelectedNetwork(null);
//               }}
//             />
//           )}
//         </div>
//       </div>
//       <div className={clsx(classes.stepItem, step >= 2 && classes.active)}>
//         <div className={classes.labelContainer}>
//           <div className={classes.number}>2</div>
//           <div className={classes.label}>Select Network </div>
//         </div>
//         <div className={classes.inputsContainer}>
//           {step >= 2 && (
//             <Dropdown
//               selectedOption={selectedNetwork}
//               setSelectedOption={setSelectedNetwork}
//               uniqueKey="select-network"
//               placeholder="Select Network"
//               options={selected_coin_networks}
//               onSelect={(val) => {
//                 setStep(3);
//               }}
//             />
//           )}
//         </div>
//       </div>
//       <div className={clsx(classes.stepItem, step >= 3 && classes.active)}>
//         <div className={classes.labelContainer}>
//           <div className={classes.number}>3</div>
//           <div className={classes.label}>Deposit Network </div>
//         </div>
//         <div
//           className={[classes.inputsContainer, classes.addressWrapper].join(
//             " "
//           )}
//         >
//           {step >= 3 && (
//             <div className={classes.addressContainer}>
//               {" "}
//               <p className={classes.addressLabel}>Deposit Address</p>
//               <div className={classes.addressWrapper}>
//                 <img src={address} alt="address" />
//                 <div className={classes.addressCopyContainer}>
//                   <small
//                     onClick={() => {
//                       setFull(!full_add);
//                     }}
//                     className={classes.address}
//                   >
//                     {!full_add
//                       ? wallet_address.substring(0, 5) +
//                       "....." +
//                       wallet_address.slice(-8)
//                       : wallet_address}
//                   </small>
//                   <Button
//                     btnPrimary
//                     size="sm"
//                     onClick={() => {
//                       window.navigator.clipboard.writeText(wallet_address);
//                     }}
//                   >
//                     Copy
//                   </Button>
//                 </div>
//               </div>
//               <div className={classes.bottomBar}>
//                 {/* <p>Deposit to</p> */}
//                 {/* <p className={classes.text}>Funding Wallet</p> */}
//                 {/* <Button btnTransparent>Edit</Button> */}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DepositCrypto;
